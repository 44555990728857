import axios from "axios";

const API_URL = `${window.location.origin}/api`;

const api = axios.create({
  baseURL: API_URL,
  timeout: 10000,
});

// Intercept any 429 error request
api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    let errorStruct = {
      status: error.response?.status,
      errors: {},
      message: "Une erreur inconnue s'est produite."
    };

    if (error.response) {
      // Si l'API Rails renvoie une réponse avec un message d'erreur
      errorStruct.message = error.response?.data?.message || error.response?.statusText;
      errorStruct.errors = error.response?.data?.errors || {};
    } else if (error.message) {
      // Erreurs sans réponse (ex: timeout, réseau)
      errorStruct.message = error.message;
    }
    return Promise.reject(errorStruct);
  }
);

export default api;
